import React from 'react'
import About from '../About/About'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Sub from '../Sub/Sub'

const About_ = () => {
  return (
   <>
     <Navbar/>
     <About/>  
     <Sub/>
        
        <Footer/>
   </>
  )
}

export default About_