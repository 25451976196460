import React from 'react'
import '../System/System.css'

const System = ({images}) => {
  return (
    <>
        
                <div className='system-items container'>
                <img src={images} alt="" />
                </div>
            
    </>
  )
}

export default System