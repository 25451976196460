import React from 'react'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Contact_ = () => {
  return (
    <>
        <Navbar/>
        <Contact/>
        <Footer/>
    </>
  )
}

export default Contact_