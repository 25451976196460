import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import SystemIndex from '../System/SystemIndex'

const Blog_ = () => {
  return (
    <>
    <Navbar/>       
    <SystemIndex/>
     <Footer/>
  </>
  )
}

export default Blog_