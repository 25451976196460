export const SystemData = [
    {
        img:'images/01.jpg'
    },
    {
        img:'images/02.jpg'
    },
    {
        img:'images/03.jpg'
    },
    {
        img:'images/04.jpg'
    },
    {
        img:'images/05.jpg'
    },
    {
        img:'images/06.jpg'
    }

]